<template>
  <TemplateSlot :orient="orient" :name="name" height="280px">
    <div class="visitorInfo">
      <div id="ageAna" ref="ageAna"></div>
      <div class="gender">
        <div class="men" :style="[menStyle]">男性占比：<span class="num">{{ ageGroupTotal.boyProp }}</span></div>
        <div class="women">女性占比：<span class="num">{{ ageGroupTotal.girlProp }}</span></div>
      </div>
    </div>
  </TemplateSlot>
</template>

<script>
import { mapState } from "vuex";
import TemplateSlot from "./TemplateSlot.vue";
export default {
  components: {
    TemplateSlot,
  },
  data() {
    return {
      value: [],
      name: "游客年龄分析",
      myChart: null,
    };
  },
  props: ["orient"],
  computed: {
    ...mapState(["ageGroup", "ageGroupData", "ageGroupTotal"]),
    menStyle() {
      return {
        width: this.ageGroupTotal.boyProp
      }
    }
  },
  mounted() {
    this.myChart = this.$echarts.init(this.$refs.ageAna,'youcha');
    this.$EventBus.$on('resizeWindow', () => {
      this.myChart.resize()
    })
    this.ageAna(this.ageGroupData, this.value);
  },
  watch: {
    ageGroupData(newVal) {
      if (newVal) {
        let sum = 0;
        newVal.forEach((el) => {
          sum += el.value;
        });
        this.value = []
        newVal.forEach((el) => {
          this.value.push(Number(((el.value / sum) * 100).toFixed(1)));
        });
        this.ageAna(newVal, this.value);
      }
    },
  },
  methods: {
    ageAna(dataInfo, value) {
      let index = 0;
      let option = {
        graphic:
        {
          id: 'age-ana',
          type: 'image',
          style: {
            image: require('../assets/ring-image-left.png'),
            width: 120,
            height: 120,
          },
          left: '16.5%',
          top: 'center'
        },
        tooltip: {
          formatter: (params) => {
            if (params.name !== "") {
              return (
                params.name +
                " : " +
                params.value +
                "\n" +
                "(" +
                params.percent +
                "%)"
              );
            }
          },
        },
        legend: {
          orient: "vertical",
          show: true,
          right: "10%",
          y: "center",
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 20,
          itemStyle: {},
          textStyle: {
            color: "#fff",
            fontSize: 14,
            lineHeight: 20,
            fontWeight: "normal",
            fontFamily: "content",
            rich: {
              percent: {
                fontFamily: "content",
                fontSize: 16,
              },
            },
          },
        },
        formatter: (name) => {
          //得到每项的value
          if (index > value.length - 1) {
            index = 0;
          }
          let tarValue = "" + value[index++];
          return `${name}\r` + " " + `{percent|${tarValue + "%"}}`;
        },
        series: [
          {
            type: "pie",
            radius: ["75%", "85%"],
            center: ["30%", "50%"],
            hoverAnimation: true,
            z: 10,
            data: dataInfo,
            labelLine: {
              show: true,
            },
            itemStyle: {
              normal: {
                borderWidth: 0,
                borderColor: "#0d2a46",
                label: {
                  show: false,
                },
              },
            },
            axis: false,
          },
        ],
      };
      this.myChart.setOption(option, true);
    },
  },
};
</script>

<style lang="scss" scoped>
.visitorInfo {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  flex-direction: column;

  .gender {
    height: 25px;
    display: flex;
    align-items: center;
    width: 75%;
    margin: 0 auto 20px;
    color: white;
    font-size: 14px;
    line-height: 25px;

    .men {
      background: rgba(26, 114, 227, 1);
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }

    .women {
      flex: 1;
      background: rgba(255, 109, 174, 1);
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }
}

#ageAna {
  width: 100%;
  margin-right: 0;
  flex: 1;
}
</style>