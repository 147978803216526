<template>
    <TemplateSlot :orient="orient" :name="name" height="280px">
        <div id="EventRanking">
            <div class="content" id="eventsReport" ref="eventsReport" style="width: 100%; height: 100%"></div>
        </div>
    </TemplateSlot>
</template>
  
<script>
import { mapState } from "vuex";
import TemplateSlot from "./TemplateSlot.vue";
export default {
    components: {
        TemplateSlot,
    },
    data() {
        return {
            name: '事件统计分析',
            myChart: null,
            labals: {
                pendingProcessing: '待处理',
                processed: '已经处理',
                complete: '已完成'
            }
        }
    },
    computed: {
        ...mapState(['userFeedInfo'])
    },
    props: ["orient"],
    mounted() {
        this.myChart = this.$echarts.init(this.$refs.eventsReport, 'youcha');
        this.$EventBus.$on('resizeWindow', () => {
            this.myChart.resize()
        })
        this.eventsReport(this.userFeedInfo)
    },
    watch: {
        userFeedInfo(newVal) {
            if (newVal) {
                this.eventsReport(newVal)
            }
        }
    },
    methods: {
        eventsReport(dataInfo) {
            let data = []
            let total = 0
            let totalMap = {}
            for (let p in dataInfo) {
                data.push({
                    value: dataInfo[p],
                    name: this.labals[p]
                })

                if (totalMap[this.labals[p]] == undefined) {
                    totalMap[this.labals[p]] = dataInfo[p]
                } else {
                    totalMap[this.labals[p]] += dataInfo[p]
                }
                total += dataInfo[p]
            }

            let option = {
                title: {
                    text: `总共 ${total} 件`,
                    left: '32%',
                    top: 'center',
                    textStyle: {
                        color: '#ffffff',
                        //fontFamily: "DS",
                        fontSize: '15px'
                    }
                },
                legend: {
                    orient: "vertical",
                    show: true,
                    right: "5%",
                    y: "center",
                    itemWidth: 10,
                    itemHeight: 10,
                    itemGap: 20,
                    itemStyle: {},
                    textStyle: {
                        color: "#fff",
                        fontSize: 14,
                        lineHeight: 20,
                        fontWeight: "normal",
                        fontFamily: "content",
                        rich: {
                            percent: {
                                fontFamily: "DS",
                                fontSize: 16,
                            },
                        },
                    },

                },
                formatter(param) {
                    return param + '  ' + totalMap[param] + '';
                },
                series: [
                    {
                        type: 'pie',
                        data: data,
                        radius: ['40%', '60%'],
                        center: ["40%", "50%"],
                        label: {
                            fontFamily: 'content',
                            formatter(param) {
                                return param.name + ' | ' + param.value + '';
                            }
                        },
                        itemStyle: {
                            normal: {
                                borderWidth: 0,
                                borderColor: "#0d2a46",
                                label: {
                                    show: false,
                                },
                            },
                        }
                    }
                ]
            };

            this.myChart.setOption(option, true);
        },
    },
};
</script>
  
<style lang="scss" scoped>
#EventRanking {
    width: 100%;
    height: 100%;


    .content {
        width: 16vh;
        height: 100%;
        box-sizing: border-box;
        padding-top: 0.4vh;
    }
}
</style>