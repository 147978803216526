<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :width="width" :height="height"
        :viewBox="viewBox" fill="none">
        <g opacity="1" transform="translate(0 1)  rotate(0)">
            <g opacity="1" transform="translate(0 0)  rotate(0)">
                <path id="路径" style="fill:url(#llinear_0); opacity:100" d="M0.5,-0.5h391.552v2h-391.552z"></path>
                <path id="矩形" fill-rule="evenodd" fill="url(#linear_1)" opacity="0.85"
                    d="M0 41.5L410 41.5L383.8 7.5L0 7.5L0 41.5Z"></path>
                <path id="路径备份" style="fill:#073C81; opacity:1;"
                    d="M420.73,38.0287c0.36933,0.3916 0.736,0.7497 1.1,1.0743c0.494,0.44013 0.942,0.78173 1.344,1.0248c0.41,0.24767 0.72367,0.3715 0.941,0.3715h72.89v2h-72.89c-0.98733,0 -2.19267,-0.63457 -3.616,-1.9037c-0.40667,-0.36287 -0.81433,-0.761 -1.223,-1.1944c-0.206,-0.2182 -0.373,-0.4023 -0.501,-0.5523l-0.017,-0.0193l-2.026,-2.6302l1.585,-1.2203l1.994,2.5891c0.10533,0.1222 0.245,0.2757 0.419,0.4605z">
                </path>
                <path id="路径备份 2" style="fill:#F76C12; opacity:1;" d="M500.5,40.5h26.5v2h-26.5z"></path>
                <path id="路径备份 3" style="fill:#073C81; opacity:1;" d="M531.5,40.5h26.5v2h-26.5z"></path>
                <path id="路径 2" style="fill:url(#llinear_2); opacity:100"
                    d="M417.154,32.3456l-2.423,1.7687l-23.44,-32.1143h-28.128v-3h29.652z"></path>
            </g>
            <path id="形状结合" fill-rule="evenodd" fill="url(#llinear_3)" opacity="1"
                d="M271.998,26.5l-12.73,-16h11.079l12.057,16zM287.347,10.5l12.057,16h-9.488l-12.056,-16zM294.86,10.5h9.487l12.057,16h-9.488zM321.347,10.5l12.057,16h-9.488l-12.056,-16zM338.347,10.5l12.057,16h-9.488l-12.056,-16zM357.898,10.5l12.72,16h-12.702l-12.056,-16z">
            </path>
        </g>
        <text x="25" y="33.5" font-size="22" text-anchor="start" fill="white">{{ title }}</text>
        <defs>
            <linearGradient id="llinear_0" x1="1.214306433183765e-15%" y1="100%" x2="100%" y2="0%"
                gradientUnits="objectBoundingBox">
                <stop offset="0.3" stop-color="rgba(158,199,252,0.253)" stop-opacity="0.1" />
                <stop offset="0.7" stop-color="#0F75FB" stop-opacity="1" />
            </linearGradient>
            <linearGradient id="linear_1" x1="95.81206783974693%" y1="38.12109213236722%" x2="-1.8648277366750676e-15%"
                y2="61.57185760780944%" gradientUnits="objectBoundingBox">
                <stop offset="0" stop-color="#1D93E4" stop-opacity="0" />
                <stop offset="1" stop-color="#328FFF" stop-opacity="1" />
            </linearGradient>
            <linearGradient id="llinear_2" x1="3.4134269747081377e-15%" y1="-3.434752482434078e-14%" x2="50%" y2="100%"
                gradientUnits="objectBoundingBox">
                <stop offset="0" stop-color="#33E3FF" stop-opacity="1" />
                <stop offset="1" stop-color="#247DFF" stop-opacity="1" />
            </linearGradient>
            <linearGradient id="llinear_3" x1="7.956852716941916%" y1="50%" x2="93.68059591027004%" y2="50%"
                gradientUnits="objectBoundingBox">
                <stop offset="0" stop-color="#1472FB" stop-opacity="0.62" />
                <stop offset="1" stop-color="#1472FB" stop-opacity="0" />
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: '游客指数'
        },
        width: {
            type: Number,
            default: 558
        },
        height: {
            type: Number,
            default: 43.5
        },
        titleLeftWidth: {
            type: Number,
            default: 50
        }
    },
    computed: {
        viewBox() {
            return `0 0 ${this.width} ${this.height}`
        }
    }
}
</script>