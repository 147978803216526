<template>
    <div class="player">
        <video v-bind="options" ref="player" class="video-js vjs-default-skin player-box">
            <source :src="url" type="application/x-mpegURL" />
        </video>
    </div>
</template>
<script>
import videojs from "video.js"
import "videojs-contrib-hls"
import video_zhCN from "video.js/dist/lang/zh-CN.json"
videojs.addLanguage('zh-CN', video_zhCN)
export default {
    props: {
        src: String,
    },
    data() {
        return {
            player: null,
            url: this.src,
            options: {
                language: 'zh-CN',
                controls: true,
                autoplay: true,
                muted: true,
                preload: 'auto',
                height: "100%",
                width: "100%"
            }
        }
    },
    watch: {
        src(val) {
            console.log(val)
            this.url = val
            this.loadVideo()
        }
    },
    methods: {
        loadVideo() {
            if (this.url) {
                this.player = videojs(this.$refs.player, this.options, () => {
                    console.log("ready")
                })
            }
        },
        pause() {
            console.log("dispose")
            if (this.player) {
                this.$refs.player.stop()
                this.player.dispose()
            }
        },
        destroyPlayer() {
            if (this.player) {
                if (this.player.pause) this.player.pause()
                if (this.player.dispose) this.player.dispose()
                if (this.player.unload) this.player.unload()
                if (this.player.detachMediaElement) this.player.detachMediaElement()
                if (this.player.destroy) this.player.destroy()
                this.player = null
            }
        },
    },
    mounted() {
        this.loadVideo()
    },
    beforeMount() {
        this.pause()
    }
}
</script>
<style>
.player {
    width: 100%;
    height: 100%;
}

.player-box {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>