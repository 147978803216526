<template>
  <div class="templateSlot">
    <div class="title">
      <blockHeaderLeft v-if="orient == 'left'" :title="name"></blockHeaderLeft>
      <blockHeaderRight v-else :title="name"></blockHeaderRight>
    </div>
    <div class="bottom" :style="{height: height}" :class="[isLeft?'view-left':'view-right']">
        <slot></slot>
    </div>
  </div>
</template>

<script>
import blockHeaderLeft from './svg/block-header-left.vue';
import blockHeaderRight from './svg/block-header-right.vue';
export default {
  components: {
    blockHeaderLeft,
    blockHeaderRight
  },
  props: {
    orient: {
      type: String,
      default: "left",
    },
    hasTitle: {
      type: Boolean,
      default: true,
    },
    height: {
      type: String,
      default: '260px',
    },
    name: {
      type: String,
      default: "游客年龄分析",
    },
  },
  computed:{
    isLeft(){
      return this.orient == 'left'
    }
  }
};
</script>

<style lang="scss" scoped>
.templateSlot {
  width: 100%;
  height: 100%;

  text-align: center;

  .title {
    width: 100%;
    height: 45px;
    position: relative;
    margin-bottom: 2px;

    svg {
      width: 100%;
      text-align: left;
    }


    .bg-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .bottom {
    width: 100%;
    position: relative;
    
    background-repeat: no-repeat;
    background-size: cover;
    
    &.view-right {
      background-image: url("../assets/view-bg-right.png");
    }
    &.view-left {
      background-image: url("../assets/view-bg-left.png");
    }
  }
}
</style>