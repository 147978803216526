<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :width="width" :height="height"
        :viewBox="viewBox" fill="none">
        <g opacity="1" transform="translate(0 1)  rotate(0)">
            <g opacity="1" transform="translate(0 0)  rotate(0)">
                <path id="路径" style="fill:url(#rlinear_0); opacity:100" d="M557.5,1.5h-391.552v-2h391.552z"></path>
                <path id="路径备份" style="fill:#073C81; opacity:1;"
                    d="M139.22,38.8489c-0.128,0.14987 -0.29467,0.33387 -0.5,0.552c-0.40867,0.43333 -0.81633,0.8314 -1.223,1.1942c-1.42133,1.2694 -2.62367,1.9041 -3.607,1.9041h-72.89v-2h72.89c0.21467,0 0.526,-0.1236 0.934,-0.3708c0.40133,-0.24307 0.84833,-0.58473 1.341,-1.025c0.36333,-0.32467 0.72967,-0.68287 1.099,-1.0746c0.17533,-0.18533 0.31567,-0.33917 0.421,-0.4615l2.004,-2.5901l1.582,1.2241l-2.035,2.6292z">
                </path>
                <path id="路径备份 2" style="fill:#F76C12; opacity:1;" d="M57.5,42.5h-26.5v-2h26.5z"></path>
                <path id="路径备份 3" style="fill:#073C81; opacity:1;" d="M26.5,42.5h-26.5v-2h26.5z"></path>
                <path id="路径 2" style="fill:url(#rlinear_1); opacity:100"
                    d="M143.272,34.1143l-2.424,-1.7687l24.34,-33.3456h29.652v3h-28.128z"></path>
            </g>
            <path id="形状结合" fill-rule="evenodd" fill="url(#rlinear_2)" opacity="1"
                d="M219.653,10.5h9.487l-12.056,16h-9.488zM200.084,26.5h-12.706l12.73,-16h12.032zM246.14,10.5l-12.056,16h-9.488l12.057,-16zM263.14,10.5l-12.056,16h-9.488l12.057,-16zM280.14,10.5l-12.056,16h-9.488l12.057,-16zM298.728,10.5l-12.72,16h-10.412l12.057,-16z">
            </path>
            <path id="矩形备份 8" fill-rule="evenodd" fill="url(#rlinear_3)" opacity="0.85"
                d="M173.826 7.5L147.626 41.5L557.626 41.5L557.626 7.5L173.826 7.5Z"></path>
        </g>
        <text x="528" y="33.5" font-size="22" text-anchor="end" fill="white">{{ title }}</text>
        <defs>
            <linearGradient id="rlinear_0" x1="1.214306433183765e-15%" y1="100%" x2="100%" y2="0%"
                gradientUnits="objectBoundingBox">
                <stop offset="0" stop-color="#0F75FB" stop-opacity="1" />
                <stop offset="0.25" stop-color="#0F75FB" stop-opacity="0.75" />
                <stop offset="0.75" stop-color="rgba(255,255,255,0)" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="rlinear_1" x1="3.4134269747081377e-15%" y1="-3.434752482434078e-14%" x2="50%" y2="100%"
                gradientUnits="objectBoundingBox">
                <stop offset="0" stop-color="#33E3FF" stop-opacity="1" />
                <stop offset="1" stop-color="#247DFF" stop-opacity="1" />
            </linearGradient>
            <linearGradient id="rlinear_2" x1="92.04314728305808%" y1="50%" x2="6.319404089729963%" y2="50%"
                gradientUnits="objectBoundingBox">
                <stop offset="0" stop-color="#1472FB" stop-opacity="0.62" />
                <stop offset="1" stop-color="#1472FB" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="rlinear_3" x1="4.187932160253071%" y1="38.12109213236722%" x2="100%" y2="61.57185760780944%"
                gradientUnits="objectBoundingBox">
                <stop offset="0" stop-color="#1D93E4" stop-opacity="0" />
                <stop offset="1" stop-color="#328FFF" stop-opacity="1" />
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: '游客指数'
        },
        width: {
            type: Number,
            default: 558
        },
        height: {
            type: Number,
            default: 43.5
        },
        titleLeftWidth: {
            type: Number,
            default: 50
        }
    },
    computed: {
        viewBox() {
            return `0 0 ${this.width} ${this.height}`
        }
    }
}
</script>