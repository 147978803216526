import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)
const indexUrl = 'screen/open/youcha'
const scenicUrl = 'guide/scenic/list'
const weatherUrl = 'common/open/youcha/weather'
const ycSpotUrl = 'screen/open/youchaSpot'
const visitorUrl = 'screen/open/beautifulTown/1/2'
const spotsUrl = 'guide/spot/list?id=1'
const spotsUrl2 = 'guide/spot/list?id=2'
const spotsUrl3 = 'guide/spot/list?id=3'
const cameraUrl = 'screen/open/beautifulTown/1/13'


export default new Vuex.Store({
    state: {
        activityInfo: [],
        ageAndTeam: [],
        ageGroup: [],
        ageGroupData: [], //
        facilityInfo: [], //
        productInfo: [], //产品
        qrCodeGroup: [], //二维码
        scanningGroup: {}, //扫码，
        shopGroup: [], //商户
        weatherInfo: {}, //天气
        ycSpotData: [], //地图点位
        scenicData: [], //地图景点
        visitorData: [], //用户流量统计
        userFeedInfo: {}, //事件统计，
        ageGroupTotal: [], //游客分析男女占比,
        spotsData: [], //热点信息
        spotsData2: [], //热点信息
        spotsData3: [], //热点信息
        camerasData: [], //摄像头信息
    },

    mutations: {
        updateAgeGroupData(state, payload) {
            state.ageGroupData = payload
        },
        handleageGroup(state) {
            state.ageGroupData = []
            state.ageGroup.forEach((el, index) => {
                let item = {
                    value: 0,
                    name: '',
                    itemStyle: {
                        color: ''
                    }
                }
                item.value = Number(el.count);
                item.name = el.groupName
                item.itemStyle.color = el.color
                state.ageGroupData.push(item)
            })
            //   //console.log(state.ageGroupData);

        },
        updateData(state, res) {
            //console.log(res.data);
            state.activityInfo = res.data.activityInfo
            state.ageAndTeam = res.data.ageAndTeam
            state.facilityInfo = res.data.facilityInfo
            state.productInfo = res.data.productInfo
            state.qrCodeGroup = res.data.qrCodeGroup
            state.scanningGroup = res.data.scanningGroup
            state.shopGroup = res.data.shopGroup.map(shop => {
                shop.date = shop.createDate
                return shop;
            })
            state.ageGroup = res.data.ageGroup
            state.ageGroupTotal = res.data.ageGroupTotal
            state.userFeedInfo = res.data.userFeedInfo
            state.ageGroupData = []
            this.commit('handleageGroup')
        },
        updateWeather(state, res) {
            state.weatherInfo = res.data
        },
        updateYcSpots(state, res) {
            state.ycSpotData = res.data
        },
        getScenicList(state, res) {
            const data = {}
            for (let scenic of res.data.data) {
                data[scenic.code] = {
                    id: scenic.id,
                    code: scenic.code,
                    url: scenic.imageFormat.replace('[z]', '{z}').replace('[x]', '{x}').replace('[y]', '{y}'),
                    center: scenic.centerPoi.split(",").map(v => parseFloat(v)),
                    southWest: scenic.lbPoi.split(",").map(v => parseFloat(v)),
                    northEast: scenic.rtPoi.split(",").map(v => parseFloat(v)),
                    zoom: scenic.zoom + 1,
                    zooms: [scenic.minZoom, scenic.maxZoom + 2]
                }
            }
            state.scenicData = data
        },
        updateVisitor(state, payload) {
            state.visitorData = payload.data
        },
        updateSpots(state, payload) {
            state.spotsData = payload.data.data
        },
        updateSpots2(state, payload) {
            state.spotsData2 = payload.data.data
        },
        updateSpots3(state, payload) {
            state.spotsData3 = payload.data.data
        },
        updateCameras(state, payload) {
            state.camerasData = payload.data
        }
    },
    actions: {
        getData(context) {
            axios.get(indexUrl).then(res => {
                context.commit('updateData', res)
            })
        },
        getWeather(context) {
            axios.get(weatherUrl).then(res => {
                context.commit('updateWeather', res)
            })
        },
        getScenicList(context) {
            axios.get(scenicUrl).then(res => {
                context.commit('getScenicList', res)
            })
        },
        getSpotList(context) {
            axios.get(spotsUrl).then(res => {
                context.commit('updateSpots', res)
            })
        },
        getSpotList2(context) {
            axios.get(spotsUrl2).then(res => {
                context.commit('updateSpots2', res)
            })
        },
        getSpotList3(context) {
            axios.get(spotsUrl3).then(res => {
                context.commit('updateSpots3', res)
            })
        },
        getYcSpotList(context) {
            axios.get(ycSpotUrl).then(res => {
                context.commit('updateYcSpots', res)
            })
        },
        getCameraList(context) {
            axios.post(cameraUrl).then(res => {
                context.commit('updateCameras', res)
            })
        },
        getVisitor(context, time) {
            axios.postForm(visitorUrl, { time }).then(res => {
                context.commit('updateVisitor', res)
            })
        }
    }
})