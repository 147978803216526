<template>
    <div class="monitor" v-if="showInfo">
        <div class="monitor_content">
            <div class="title">
                <div class="title_content">摄像头编号：{{ title }}</div>
                <div class="tabs">
                    <div class="tab-item" :class="{ active: index == tabIndex }" v-for="(name, index) of tabs" :key="index"
                        @click="handleTabClick(index)">
                        {{ name }}
                    </div>
                </div>
                <div class="close_btn" @click="handleClose">
                    <img src="../assets/dialog-close.png" alt="">
                </div>
            </div>
            <div class="content">
                <Player ref="player" :src="video" v-if="tabIndex==0"></Player>
                <MonitorHistory v-else :device-no="title"></MonitorHistory>
            </div>
        </div>
    </div>
</template>

<script>
import Player from "./player.vue"
import MonitorHistory from "./MonitorHistory.vue"
export default {
    components: {
        Player,
        MonitorHistory
    },
    props: {
        video: String,
        title: String,
        show: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showInfo: this.show,
            currentIndex: 0,
            tabIndex: 0,
            tabs: ['直播', '回放'],
        }
    },
    watch: {
        show(val) {
            this.showInfo = val
            if(this.showInfo == false) {
                if(this.$refs.player){
                    console.log("play pause")
                    this.$refs.player.pause()
                }
            }
        }
    },
    methods: {
        handleTabClick(index) {
            this.tabIndex = index;
            console.log(this.tabIndex)
            //this.$store.dispatch('getVisitor', this.times[index]);
        },
        handleClose() {
            this.$emit('update:show', false)
            this.$refs.player.destroyPlayer()
        },

        tabSelect(index) {
            this.currentIndex = index
        }
    }
}
</script>
<style lang="scss" scoped>
.monitor {
    position: absolute;
    width: 100%;
    z-index: 9999;
    top: 16vh;
    left: 0;
    // box-sizing: border-box;
    // border: 0.2vh solid #28728c;


    .monitor_content {
        position: relative;
        margin: auto;
        width: 600px;
        height: 400px;
        background-image: url("../assets/dialog-bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 20px 30px 30px 15px;

        .title {
            width: 100%;
            height: 50px;
            padding-left: 10px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;

            .title_content {
                //height:100px;
                line-height: 50px;
                font-family: title;
                font-size: 18px;
                text-align: left;
                color: #fff;
            }


            .close_btn {
                width: 40px;
                height: 40px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .content {
            width: 540px;
            height: 300px;
            display: flex;
        }
    }
}
</style>