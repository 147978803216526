<template>
  <TemplateSlot :orient="orient" :hasTitle="true" :name="name">
    <div id="Varieties" ref="varieties"></div>
  </TemplateSlot>
</template>

<script>
import { mapState } from "vuex";
import TemplateSlot from "./TemplateSlot.vue";
import "echarts-gl";
export default {
  components: {
    TemplateSlot,
  },
  computed: {
    ...mapState(['productInfo'])
  },
  data() {
    return {
      optionData: [],
      option: {},
      name: '油茶品种占比',
      myChart: null,
    };
  },
  props: ["orient"],
  mounted() {
    this.myChart = this.$echarts.init(this.$refs.varieties, 'youcha');
    this.$EventBus.$on('resizeWindow', () => {
      this.myChart.resize()
    })
    this.varieties();
  },
  watch: {
    productInfo(newVal) {
      if (newVal) {
        this.optionData = []
        this.productInfo.forEach(el => {
          let obj = {
            name: '',
            value: 0
          }
          obj.name = el.productName
          obj.value = Number(el.count)
          this.optionData.push(obj)
        })
        this.varieties()
      }
    }
  },
  methods: {
    varieties() {

      // 传入数据生成 option, 构建3d饼状图, 参数工具文件已经备注的很详细
      this.option = {
        backgroundColor: 'transparent',
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: "vertical",
          show: true,
          right: "20px",
          y: "20px",
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 20,
          itemStyle: {},
          textStyle: {
            color: "#fff",
            fontSize: 14,
            lineHeight: 20,
            fontWeight: "normal",
            rich: {
              percent: {
                fontFamily: "content",
                fontSize: 12,
              },
            },
          },
        },
        series: [
          {
            type: 'pie',
            radius: '55%',
            center: ["30%", "50%"],
            data: this.optionData.sort(function (a, b) {
              return b.value - a.value;
            }),
            roseType: 'radius',
            itemStyle: {
              color: '#fff',
              shadowBlur: 200,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
              normal: {
                borderWidth: 0,
                borderColor: "#0d2a46",
                label: {
                  show: false,
                },
              },
            },
            animationType: 'scale',
            animationEasing: 'elasticOut',
            animationDelay: function (idx) {
              return Math.random() * 200;
            }
          }
        ]
      }
      this.myChart.setOption(this.option, true)

    },
  },
};
</script>

<style lang="scss" scoped>
#Varieties {
  width: 100%;
  height: 100%;
}
</style>../plugin/chart